import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { FiscalInvoice } from 'src/app/model/fiscal/fiscalInvoice';
import { Importacao } from 'src/app/model/importacao/importacao';
import { ImportacaoDocumento } from 'src/app/model/importacao/importacaoDocumento';
import { ImportacaoInvoice } from 'src/app/model/importacao/importacaoInvoice';
import { Pessoa } from 'src/app/model/pessoa/pessoa';
import { FiscalInvoiceService } from 'src/app/services/fiscal/fiscal-invoice.service';
import { ImportacaoDocumentoService } from 'src/app/services/importacao/importacao-documento.service';
import { ImportacaoInvoiceService } from 'src/app/services/importacao/importacao-invoice.service';
import { ImportacaoService } from 'src/app/services/importacao/importacao.service';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { FormGenerico } from '../../../generic/formgenerico/formgenerico';
import { ImportacaoHistorico } from 'src/app/model/importacao/importacaoHistorico';
import { FiscalInvoiceItensService } from 'src/app/services/fiscal/fiscal-invoice-itens.service';
import { AppConfig } from 'src/app.config';
import { FormImportacaoDocumentoComponent } from '../formimportacaodocumento/formimportacaodocumento.component';
import { FormUploadComponent } from '../../../generic/formupload/formupload.component';
import { ListaTraducaoComponent } from '../../listas/listatraducao/listatraducao.component';

@Component({
  selector: 'app-formimportacao',
  templateUrl: './formimportacao.component.html',
  styleUrls: ['../../../generic/formgenerico/formgenerico.component.css'],
})
export class FormImportacaoComponent extends FormGenerico<Importacao> implements OnInit {

  displayAddInvoice: boolean = false;
  displayPDF = false;
  displayImgInvoice: boolean = false;
  atualizarArqInv = false;
    
  objImpInvoiceSel: ImportacaoInvoice;
  idImpSel: number = 0;
  nomeArqSel: string;

  objImpDocSel: ImportacaoDocumento;

  itemsFunctionsInvoice: MenuItem[];
  itemsFunctionsDoc: MenuItem[];
  itemsFunctionsFin: MenuItem[];

  selectedFiscalInvoices: FiscalInvoice[];
  totalFiscalInvoice: number = 0;
  totalVolumeFiscalInvoice: number = 0;
  totalImpInvoiceFat: number = 0;
  totalImpInvoiceAWB: number = 0;
  totalImpInvoicePesoWr: number = 0;
  totalImpInvoiceWr: number = 0;
  percCusto: number = 0;
  prefixoArqInv: string;
  
  listaDocs: ImportacaoDocumento[]; // fazer filtro pelo rótulo DOC
  listaPgtos: ImportacaoDocumento[]; // fazer filtro pelo rótulo FIM
  listaFiscalInvoices: FiscalInvoice[];
  indiceFiscalInvoiceSel: number;
  listaimpHistorico: ImportacaoHistorico[];
  listaFiscalInvoicesItens: any[];
  colsFiscalInvoiceItens: any;

  dadosGraficoPgtos: any;
  optGraficoPgtos: any;
  totalPgtos: number = 0;
  valorNfe: number = 0;
  cotacaoNfe: number = 0;

  pessoaAgenteCarga: Pessoa = new Pessoa();
  pessoaAgenteCarga2: Pessoa = new Pessoa();
  pessoaAgenteAduaneiro: Pessoa = new Pessoa();
  pessoaAgenteAlfandegado: Pessoa = new Pessoa();
  pessoaTranspBr: Pessoa = new Pessoa();

  urlString: string;

  constructor(
    injectorObj: Injector,
    public api: ImportacaoService,
    public apiImportacaoDocumento: ImportacaoDocumentoService,
    public apiFiscalInvoice: FiscalInvoiceService,
    public apiFiscalInvoiceItens: FiscalInvoiceItensService,
    public apiImportacaoInvoice: ImportacaoInvoiceService,
    public apiPessoa: PessoaService,
    private http: HttpClient
  ) {
    super(injectorObj, api);
    this.obj = new Importacao();

    this.itemsFunctions.push({ label: this.translocoService.translate('button_timeline'), command: () => { this.openTimeline(); } });

    this.itemsFunctionsInvoice = [
      { label: this.translocoService.translate('button_attach_invoice'), command: () => { this.showAddFilesInvoice('inv', this.translocoService.translate('button_attach_invoice')); } }, // Anexar Arquivos Invoice
      { label: this.translocoService.translate('button_attach_pk'), command: () => { this.showAddFilesInvoice('pkl', this.translocoService.translate('button_attach_pk')); } }, // Anexar Arquivos WR
      { label: this.translocoService.translate('button_attach_wr'), command: () => { this.showAddFilesInvoice('wr', this.translocoService.translate('button_attach_wr')); } }, // Anexar Arquivos WR
      { separator: true },
      { label: this.translocoService.translate('button_view_items'), command: () => { this.showItensInvoice(); } }, // Visualizar Itens
      { separator: true },
      { label: this.translocoService.translate('hint_button_delete'), command: () => { this.deleteItens(this.objImpInvoiceSel, this.indiceFiscalInvoiceSel);
                                                                                       this.calcularTotaisInvoice(); } },
      { separator: true },
      { label: this.translocoService.translate('button_update_invoice'), command: () => { this.updateFreight(); } }, // Atualizar Invoice
      ];

    this.itemsFunctionsDoc = [
      { label: this.translocoService.translate('hint_button_view'), command: () => { this.showDocs('DOC', 'V') } }, // Visualizar
      { label: this.translocoService.translate('hint_button_update'), command: () => { this.showDocs('DOC', 'A') } }, // Alterar
      { separator: true },
      { label: this.translocoService.translate('hint_button_delete'), command: () => { this.deleteDoc(this.objImpDocSel); } }, // Excluir
    ];

    this.itemsFunctionsFin = [
      { label: this.translocoService.translate('hint_button_view'), command: () => { this.showDocs('FIN', 'V') } }, // Visualizar
      { label: this.translocoService.translate('hint_button_update'), command: () => { this.showDocs('FIN', 'A') } }, // Alterar
      { separator: true },
      { label: this.translocoService.translate('hint_button_delete'), command: () => { this.deleteFin(this.objImpDocSel); } }, // Excluir
    ];
  }
  
  override ngOnInit(): void {
    super.ngOnInit();

     //Exemplo
     //this.findAllGeneric(this.apiEndEstado).then(res => this.listaEstados = res);
  }

  atualizarPessoaAgenteCarga(objSelFilho) {
    this.pessoaAgenteCarga = objSelFilho;
  }
  atualizarPessoaAgenteCarga2(objSelFilho) {
    this.pessoaAgenteCarga2 = objSelFilho;
  }
  atualizarPessoaAgenteAduaneiro(objSelFilho) {
    this.pessoaAgenteAduaneiro = objSelFilho;
  }
  atualizarPessoaAgenteAlfandegado(objSelFilho) {
    this.pessoaAgenteAlfandegado = objSelFilho;
  }
  atualizarPessoaTranspBr(objSelFilho) {
    this.pessoaTranspBr = objSelFilho;
  }

  dataExtraObj(res: any) {
    // Ajustando Datas
    this.obj.dataAb = new Date(this.obj.dataAb);
    if (this.obj.dataFech) {
      this.obj.dataFech = new Date(this.obj.dataFech);
    }
    if (this.obj.dataChegada) {
      this.obj.dataChegada = new Date(this.obj.dataChegada);
    }
    
    if ((this.obj.pessoaAgenteCarga) && (this.obj.pessoaAgenteCarga.id > 0)) {
      this.pessoaAgenteCarga = this.obj.pessoaAgenteCarga;
    }
    if ((this.obj.pessoaAgenteCarga2) && (this.obj.pessoaAgenteCarga2.id > 0)) {
      this.pessoaAgenteCarga2 = this.obj.pessoaAgenteCarga2;
    }
    if ((this.obj.pessoaAgenteAduaneiro) && (this.obj.pessoaAgenteAduaneiro.id > 0)) {
      this.pessoaAgenteAduaneiro = this.obj.pessoaAgenteAduaneiro;
    }
    if ((this.obj.pessoaAgenteAlfandegado) && (this.obj.pessoaAgenteAlfandegado.id > 0)) {
      this.pessoaAgenteAlfandegado = this.obj.pessoaAgenteAlfandegado;
    }
    if ((this.obj.pessoaTranspBr) && (this.obj.pessoaTranspBr.id > 0)) {
      this.pessoaTranspBr = this.obj.pessoaTranspBr;
    }

    this.findItens();
  }

  findItens() {
    this.api.findAllItens(this.auth.empresa.id, this.obj.id) 
      .toPromise()
      .then
      ((res: any) => {
        this.obj.listaItens = res;

        this.findAllImpDocs("DOC").then(res => this.listaDocs = res); //Importação Documentos
        this.findAllPgtos();
      })
      .catch
      (( error ) => {
        this.errorHandler.handle(error);
      });
  }

  //Lista Importacao Doc / Financeiro
  findAllImpDocs(tipo: string) {
    return this.apiImportacaoDocumento.findImpDoc(this.obj.id, tipo)
      .toPromise()
      .then
      ((res: any) => {
        return res;
      });
  }

  findAllPgtos() {
    this.findAllImpDocs("FIN").then(res => {
      this.listaPgtos = res; 
      this.api.returnValorNfe(this.auth.empresa.id, this.obj.id).toPromise().then((res: any) => { this.valorNfe = res; this.preencherCustosPgtos(); });
      this.api.returnCotacaoNfe(this.auth.empresa.id, this.obj.id).toPromise().then((res: any) => { this.cotacaoNfe = res });
      this.calcularTotaisInvoice();
    }); 
  }

  preencherCustosPgtos() {
    this.totalPgtos = 0;
    this.listaPgtos.forEach(objPgto => {
      let valor = objPgto.valorFin * 1;
      if ((objPgto.tipo == 'D') && (valor))
        valor = objPgto.valorFin * -1;

      if (valor)
        this.totalPgtos = this.totalPgtos + valor;

      if (objPgto.importacaoTipoDocumento.calcCusto == 'SIM')
        objPgto.custoPerc = valor / this.valorNfe * 100;
      else
        objPgto.custoPerc = 0;
      });
    this.percCusto = this.globalFunctions.calcularTotais('custoPerc', this.listaPgtos, undefined);
  }

  //Lista Fiscal Invoice ShowDialog
  findAllFiscalInvShowDialog() {
    this.displayAddInvoice = true;
    this.apiFiscalInvoice.findFilterStatus(this.auth.empresa.id, 'PROCESSADA')
      .toPromise()
      .then
      ((res: any) => {
        this.listaFiscalInvoices = res;
        this.totalFiscalInvoice = this.globalFunctions.calcularTotais('totFaturado', this.selectedFiscalInvoices, undefined);
        this.totalVolumeFiscalInvoice = this.globalFunctions.calcularTotais('transpVolumeNum', this.selectedFiscalInvoices, undefined);
      });
  }

  //Upload Importação Documento/Financeiro
  uploadImpDoc(event, idImpDoc) {
    if (event.target.files && event.target.files[0]) {
      const upload = event.target.files[0];

      const formData = new FormData();
      formData.append('upload', upload);

      this.http.post(AppConfig.urlBasic + `importacaodocumento/upload/${idImpDoc}`, formData).subscribe();
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Salva Com Sucesso!' });
    }
  }
  
  //Chamada do Button que adicona Fiscal Invoice Para EDIÇÃO na Importação Invoice
  adicionarInvoice() {
    if (!this.obj.listaItens) {
      this.obj.listaItens = [];
    }
    this.selectedFiscalInvoices.forEach(objFiscalInvoice => {
      let objImportacaoInvoice: ImportacaoInvoice = new ImportacaoInvoice();
      objImportacaoInvoice.fiscalInvoice = objFiscalInvoice;
      this.obj.listaItens.push(objImportacaoInvoice)
      this.displayAddInvoice = false;
    });
    this.calcularTotaisInvoice();
  }

  //Calculo Fiscal Invoice (ShowDialog)
  onRowSelect(event) {
    this.totalFiscalInvoice = this.globalFunctions.calcularTotais('totFaturadoMoeda', this.selectedFiscalInvoices, undefined);
    this.totalVolumeFiscalInvoice = this.globalFunctions.calcularTotais('transpVolumeNum', this.selectedFiscalInvoices, undefined);
  }

  override save() {
    console.log(this.pessoaAgenteCarga);
    
    if ((this.pessoaAgenteCarga) && (this.pessoaAgenteCarga.id > 0)) 
      this.obj.pessoaAgenteCarga = this.pessoaAgenteCarga;
    else
      this.obj.pessoaAgenteCarga = null;

    if ((this.pessoaAgenteCarga2) && (this.pessoaAgenteCarga2.id > 0)) 
      this.obj.pessoaAgenteCarga2 = this.pessoaAgenteCarga2;
    else
      this.obj.pessoaAgenteCarga2 = null;

    if ((this.pessoaAgenteAduaneiro) && (this.pessoaAgenteAduaneiro.id > 0))
        this.obj.pessoaAgenteAduaneiro = this.pessoaAgenteAduaneiro;
    else 
        this.obj.pessoaAgenteAduaneiro = null;
        
    if ((this.pessoaAgenteAlfandegado) && (this.pessoaAgenteAlfandegado.id > 0))
        this.obj.pessoaAgenteAlfandegado = this.pessoaAgenteAlfandegado;
    else 
        this.obj.pessoaAgenteAlfandegado = null;

    if ((this.pessoaTranspBr) && (this.pessoaTranspBr.id > 0))
        this.obj.pessoaTranspBr = this.pessoaTranspBr;
    else 
        this.obj.pessoaTranspBr = null;

    super.save(true);
  }

  getUrlAnexarImagensImpInvoice() {
    return 'upload/' + this.prefixoArqInv + '/' + this.idImpSel;
  }

  //Lista de Upload da Importacao Invoice (ShowDialog)
  /*showAddInvoice(prefixo: string) {
    this.prefixoArqInv = prefixo;
    this.displayImgInvoice = true;
    this.titleDialog = this.translocoService.translate('button_attach') + ' - ' + this.objImpInvoiceSel.fiscalInvoice.numTitulo
  }*/

  showAddFilesInvoice(prefixo: string, titulo: string) {
    this.prefixoArqInv = prefixo;
    this.displayImgInvoice = true;

    let fn = () => this.atualizarArquivosInvoice();
    this.showDialogDef(FormUploadComponent, {api: this.apiImportacaoInvoice, obj: this.objImpInvoiceSel, infoExtra: [prefixo] }, titulo + ' - ' + this.objImpInvoiceSel.fiscalInvoice.numTitulo, fn);
  }

  selectImpDoc(objImpDoc: ImportacaoDocumento) {
    this.objImpDocSel = objImpDoc;
  }

  deleteDoc(objDoc: any) {
    this.delete(this.apiImportacaoDocumento, objDoc, 'Documento').then(
      res => { this.findAllImpDocs("DOC").then(res => this.listaDocs = res); });
  }

  deleteFin(objDoc: any) {
    this.delete(this.apiImportacaoDocumento, objDoc, 'Documento').then(
      res => { this.findAllImpDocs("FIN").then(res => this.listaPgtos = res); });
  }

  selectImpInvoice(objImpInvoice: ImportacaoInvoice, indice: number) {
    this.objImpInvoiceSel = objImpInvoice;
    this.idImpSel = objImpInvoice.id;
    this.indiceFiscalInvoiceSel = indice;
  }

  showDocs(tipoSel: string, acaoExec: string) {
    let fn, idDoc, titulo, tipoDoc;
    if (tipoSel == 'DOC') {
      fn = () => this.findAllImpDocs(tipoSel).then(res => {this.listaDocs = res;});
    }
    else {
      fn = () => this.findAllPgtos();
    }
    titulo = this.obj.numero;
    if (acaoExec == 'I') {
      idDoc = 0;
      tipoDoc = undefined;
    }
    else {
      idDoc = this.objImpDocSel.id;
      titulo = titulo + ' / Doc: ' + this.objImpDocSel.importacaoTipoDocumento.nome + ' - ' + this.objImpDocSel.numero;
      tipoDoc = this.objImpDocSel.importacaoTipoDocumento
    }
    this.showDialogDef(FormImportacaoDocumentoComponent, {tipo: tipoSel, acao: acaoExec, id: idDoc, idImportacao: this.obj.id, objImportacaoTipoDoc: tipoDoc, fixo: 'NÃO' }, titulo, fn) 
  }

  //Lista de Itens da Invoice (ShowDialog)
  showItensInvoice() {
    this.apiFiscalInvoiceItens.findItensPorInvoiceId(this.auth.empresa.id, this.objImpInvoiceSel.fiscalInvoice.id)
    .toPromise()
    .then
    ((res: any) => {

      this.listaFiscalInvoicesItens = [];
      res.forEach(obj => {
        this.listaFiscalInvoicesItens.push({"PN": obj.adendoPecas.pn, 
          "Descrição": obj.adendoPecas.descricao, "Fabricante": obj.fabricante.nome, "Quantidade": obj.quant,
          "Valor Unit": obj.valorUnit, "Subtotal": obj.subtotal, "PO": obj.venda.numCliente})
      }); 

      this.colsFiscalInvoiceItens = [];
      this.colsFiscalInvoiceItens.push({ field: 'PN', header: this.translocoService.translate('label_sysfield_1976'), type: 'Texto'});
      this.colsFiscalInvoiceItens.push({ field: 'Descrição', header: this.translocoService.translate('label_sysfield_4147'), type: 'Texto'});
      this.colsFiscalInvoiceItens.push({ field: 'Quantidade', header: this.translocoService.translate('label_sysfield_4070'), type: 'N° Real'});
      this.colsFiscalInvoiceItens.push({ field: 'Valor Unit', header: this.translocoService.translate('label_sysfield_4074'), type: 'N° Real'});
      this.colsFiscalInvoiceItens.push({ field: 'Subtotal', header: this.translocoService.translate('label_sysfield_4076'), type: 'N° Real'});
      this.colsFiscalInvoiceItens.push({ field: 'Fabricante', header: this.translocoService.translate('label_sysfield_4163'), type: 'Texto'});
      this.colsFiscalInvoiceItens.push({ field: 'PO', header: this.translocoService.translate('label_sysfield_4928'), type: 'Texto'});

      this.showDialogDef(ListaTraducaoComponent, {list: this.listaFiscalInvoicesItens, cols: this.colsFiscalInvoiceItens }, this.objImpInvoiceSel.fiscalInvoice.numTitulo, undefined);      
    });
  }

  handleOnInput($event: any) {
    this.calcularTotaisInvoice();
  } 

  calcularTotaisInvoice() {
    this.totalImpInvoiceFat = this.globalFunctions.calcularTotais('totFaturadoMoeda', this.obj.listaItens, 'fiscalInvoice')
    this.totalImpInvoiceAWB = this.globalFunctions.calcularTotais('valorFreteInter', this.obj.listaItens, 'fiscalInvoice')
    this.totalImpInvoicePesoWr = this.globalFunctions.calcularTotais('wrPeso', this.obj.listaItens, undefined);
    this.totalImpInvoiceWr = this.totalImpInvoicePesoWr * this.obj.valorUnitWr;
  }

  atualizarArquivosInvoice() {
    this.atualizarArqInv = !this.atualizarArqInv;
  }

  openTimeline() {
    let link = `/importacao/forms/formimportacaotimeline`;
    this.router.navigate([`${link}/`], { queryParams: { idImportacao: this.obj.id } });
  }

  updateFreight() {
    if ((this.objImpInvoiceSel.wrPeso > 0) && (this.obj.valorUnitWr > 0)) {
      this.confirmationService.confirm({
        message: this.translocoService.translate('mens_update_pre_confirm') + ' Invoice ' + this.objImpInvoiceSel.fiscalInvoice.numTitulo,
        header: this.translocoService.translate('action_update'),
        icon: 'pi pi-question-circle',
        acceptLabel: this.translocoService.translate('yes'),
        rejectLabel: this.translocoService.translate('no'),
        accept: () => {
              let valorTemp = this.objImpInvoiceSel.fiscalInvoice.valorFreteInter;
              this.objImpInvoiceSel.fiscalInvoice.valorFreteInter = this.objImpInvoiceSel.wrPeso * this.obj.valorUnitWr;
              this.apiFiscalInvoice.updateFreight(this.objImpInvoiceSel.fiscalInvoice.id, this.objImpInvoiceSel.fiscalInvoice).toPromise()
              .then
              ((res: any) => {
                let objFiscalInvoice = res;
                const message = this.translocoService.translate('message_update_sucess');
                this.messageService.add({severity:'success', summary: message, detail:''});
              })
              .catch
              (( error ) => {
                this.objImpInvoiceSel.fiscalInvoice.valorFreteInter = valorTemp;
                this.errorHandler.handle(error);
              });
        },
        reject: () => {
        }
      });
    }
    else {
      this.errorHandler.handle('Total WR > zero!');
    }
  }

  getColorCell(valor: any) {  
    if (valor == 'C')
        return 'var(--green-100)'
    else if (valor == 'D')
      return 'var(--red-100)'
    else
      return undefined;
  }

}