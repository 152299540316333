import { Padrao } from "../generic/padrao";
import { SysTabela } from "./sysTabela";

export class SysCampo extends Padrao{

    override idTab = 77;

    sysTabela: SysTabela = new SysTabela();
    nome: string;
    apelidoGrid: string;
    descricao: string;
    tipo: string;
    relevante: string;
    relevantePesq: string;
    relevantePesqDinamica: string;
    tamHorz: number;
    tamHorzWeb: number;
    tamVert: number;
    ordem: number;
    ordemPesq: number;
    interno: string;
    prefixo: string;
    formato: string;
    identificador: string;
    intervaloDataInicio: number;
    intervaloDataTermino: number;
    alinTitulo: string;
    alinConteudo: string;
    tipoOrdem: string;
    tipoOrdemPesq: string;
    campoCalc: string;
    agrupado: string;
    nomeProp: string;
    obrigatorio: string;
    inserir: string;
    alterar: string;
    visivel: string;
    habilitado: string;
    grupoForm: string;
    maxCarac: number;
    ordemCad: number;
    nomePropId: string;
    nomePropVisao: string;
    classeNomePesq: string;
    tipoWeb: string;
    utilWeb: string;
    opcoes: string;
    calcRodape: string;
}