<div #topbar class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="home">
        <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-empresa-dark' : 'logo-empresa-light'}}.png" alt="Logo Empresa">
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <p-menu #menuConfig [popup]="true" [model]="itensConfig" appendTo="body"></p-menu>
<!--
    <p-overlayPanel #menuUsuario [showCloseIcon]="true" [style]="{width: '450px'}">
        <ng-template pTemplate>
            <p-avatar image="layout/images/000001.png" styleClass="mr-2" size="large" shape="circle"></p-avatar>    
        </ng-template>
    </p-overlayPanel>
-->

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button class="p-link layout-topbar-button">
            <a routerLink="/bi"><i class="pi pi-qrcode"></i>
            </a>
            <span>BI</span>
        </button>
        <button class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>
                {{'field_default_user' | transloco }}
            </span>
        </button>
        <button #btnConfig class="p-link layout-topbar-button" (click)="menuConfig.toggle($event)">
            <i class="pi pi-cog"></i>
            <span>
                {{'button_config' | transloco }}
            </span>
        </button>
        <img class="p-link layout-topbar-button" src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-aerosys-dark' : 'logo-aerosys-light'}}.png" alt="Logo Empresa">
    </div>
</div>