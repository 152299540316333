<div class="grid p-fluid m-0 p-0">
    <div class="grid p-fluid m-0 p-0 col-6 md:col-{{larguraCampos[0]}}">
        <label *ngIf="textButton.length == 0" for="inputIdPesq">{{ constantsAero.PREFIX_FIELD + labels[0] | transloco }}</label>
        <div class="p-inputgroup">
            <button type="button" pButton pRipple icon="pi pi-search" (click)="showDialog()" [disabled]="disabled">{{ ' . ' + textButton}}</button> 
            <p-inputNumber [(ngModel)]="obj.id" inputId="integeronly" id="inputIdPesq" name="inputIdPesq" [min]="1" [disabled]="true"></p-inputNumber>
            <button *ngIf="limpar" type="button" pButton pRipple icon="pi pi-times" (click)="clearObj()" [disabled]="disabled" class="p-button-outlined p-button-secondary"></button> 
        </div>
    </div>
    <div *ngIf="qtdProperties >= 1" class="m-0 pb-0 p-0 field col-12 md:col-{{larguraCampos[1]}}">
        <label for="inputValor1Pesq">{{ constantsAero.PREFIX_FIELD + labels[1] | transloco }}</label>
        <input id="inputValor1Pesq" [ngModel]="obj[properties[0]]" name="inputValor1Pesq" type="text" pInputText [disabled]="true"/>
    </div>
    <div *ngIf="qtdProperties >= 2" class="m-0 pb-0 p-0 field col-12 md:col-{{larguraCampos[2]}}">
        <label for="inputValor2Pesq">{{ constantsAero.PREFIX_FIELD + labels[2] | transloco }}</label>
        <input id="inputValor2Pesq" [ngModel]="obj[properties[1]]" name="inputValor2Pesq" type="text" pInputText [disabled]="true"/>
    </div>
    <div *ngIf="qtdProperties >= 3" class="m-0 pb-0 p-0 field col-12 md:col-{{larguraCampos[3]}}">
        <label for="inputValor3Pesq">{{ constantsAero.PREFIX_FIELD + labels[3] | transloco }}</label>
        <input id="inputValor3Pesq" [ngModel]="obj[properties[2]]" name="inputValor3Pesq" type="text" pInputText [disabled]="true"/>
    </div>
</div>