import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorHandlerService {

  constructor(
    private messageService: MessageService,
    private translocoService: TranslocoService
  ) { }

  handle(errorResponse: any) {
    let msg: string;

    if (typeof errorResponse === 'string') {
      msg = errorResponse;
      //console.log(msg);
    } else if (errorResponse instanceof HttpErrorResponse
        && errorResponse.status >= 400 && errorResponse.status <= 499) {
      msg = this.translocoService.translate('message_process_failure');

      if (errorResponse.status === 403) {
        msg = this.translocoService.translate('message_login_permission');
      }
      try {
        msg = errorResponse.error[0].mensagemUsuario;
      } catch (e) { }

      console.error(this.translocoService.translate('message_general_error'), errorResponse);

    } else {
      msg = this.translocoService.translate('message_general_failure');
      console.error(this.translocoService.translate('message_general_error'), errorResponse);
    }
    this.messageService.add({ severity:'error', detail: msg });
  }
}