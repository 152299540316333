import { Empresa } from './../../model/empresa/empresa';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends GenericService<Empresa>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("empresa")
  }
}
