import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormCamposPadroesComponent } from './formcampospadroes/formcampospadroes.component';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ListaPesquisaGenericaComponent } from './listapesquisagenerica/listapesquisagenerica';
import { DialogModule } from 'primeng/dialog';
import { ListaComponent } from './listapesquisa/lista.component';
import { BadgeModule } from 'primeng/badge';
import { FormUploadComponent } from './formupload/formupload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ListaArquivosComponent } from './listaarquivos/listaarquivos.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DataViewModule } from 'primeng/dataview';
import { CarouselModule } from 'primeng/carousel';
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TimelineModule } from 'primeng/timeline';
import { DateMaskDirective } from 'src/app/diretivas/date-mask.directive';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FormViewPdfComponent } from './formviewpdf/formviewpdf.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,

		AutoCompleteModule,
		CalendarModule,
		ChipsModule,
		DropdownModule,
		InputMaskModule,
		InputNumberModule,
		ColorPickerModule,
		CascadeSelectModule,
		MultiSelectModule,
		ToggleButtonModule,
		SliderModule,
		InputTextareaModule,
		RadioButtonModule,
		InputTextModule,
		RatingModule,
		ChipModule,
		KnobModule,
		InputSwitchModule,
		ListboxModule,
		SelectButtonModule,
		CheckboxModule,
		ButtonModule,
		TableModule,
		InputNumberModule,
		DropdownModule,
		TieredMenuModule,
		DialogModule,
		InputNumberModule,
		InputMaskModule,

		TranslocoModule,
		ConfirmDialogModule,
		MenuModule,
		MenubarModule,
		BadgeModule,
		FileUploadModule,
		ToastModule,
		PdfViewerModule,
		DataViewModule,
		CarouselModule,
		CardModule,
		ImageModule,
		ScrollPanelModule,
		SplitButtonModule,
		MultiSelectModule,
		TimelineModule,
		DynamicDialogModule
	],
	declarations: [
		FormCamposPadroesComponent,
		FormUploadComponent,
		FormViewPdfComponent,

		ListaPesquisaGenericaComponent,
		ListaComponent,
		ListaArquivosComponent,
	],
	exports: [
		FormCamposPadroesComponent,
		FormUploadComponent,
		FormViewPdfComponent,

		ListaPesquisaGenericaComponent,
		ListaComponent,
		ListaArquivosComponent,
	]

})
export class GenericModule { }
