import { Padrao } from '../generic/padrao';

export class SysTabela extends Padrao{

    override idTab = 76;

    nome: string;
    nomeObj: string;
    descricao: string;
    campoPadrao: number;
    instrucaoPadrao: string;
    instrucaoPesqRapida: string;
    cadastro: string;
    pesqAuto: string;
    grupBy: string;
    sumario: string;
    ordem: string;
}