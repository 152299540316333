import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ImportacaoListaModule } from './importacaolista.module';
import { ListaSimplesGenerica } from '../../generic/listagenerica/listasimplesgenerica';
import { ImportacaoService } from 'src/app/services/importacao/importacao.service';
import { Importacao } from 'src/app/model/importacao/importacao';
import { FormImportacaoDocumentoComponent } from '../forms/formimportacaodocumento/formimportacaodocumento.component';
import { Pessoa } from 'src/app/model/pessoa/pessoa';
import { FormImportacaoTimeLineComponent } from '../forms/formimportacaotimeline/formimportacaotimeline.component';
import { ImportacaoDocumento } from 'src/app/model/importacao/importacaoDocumento';
import { ImportacaoDocumentoService } from 'src/app/services/importacao/importacao-documento.service';
import { ListaTraducaoComponent } from './listatraducao/listatraducao.component';

@Component({
  selector: 'app-lista-importacao',
  templateUrl: '../../generic/listagenerica/listasimples.component.html',
  styleUrls: [ '../../generic/listagenerica/listasimples.component.css']
})

export class ListaImportacaoComponent extends ListaSimplesGenerica<Importacao> implements OnInit {

  override obj = new Importacao();
  objPessoa = new Pessoa();

  listaImpDocsFixos: ImportacaoDocumento[] = [];
  
  constructor(
    injectorObj: Injector,
    api: ImportacaoService,
    private apiImportacaoDocumento: ImportacaoDocumentoService) 
  {
    super(injectorObj, api);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.itemsFunctions.push( 
      { separator: true },
      { label: this.translocoService.translate(this.constantsAero.PREFIX_FIELD + '5716') ,
      items: [
        {label: 'Branco', command: () => { this.updateCanal('Branco'); } },
        {label: 'Cinza', command: () => { this.updateCanal('Cinza'); } },
        {label: 'Amarelo', command: () => { this.updateCanal('Amarelo'); } },
        {label: 'Verde', command: () => { this.updateCanal('Verde'); } },
        {label: 'Vermelho', command: () => { this.updateCanal('Vermelho'); } },
      ]},
      { separator: true },
      { label: this.translocoService.translate(this.constantsAero.PREFIX_TABLE + '280'),
        items: [
          {label: this.translocoService.translate('button_insert'), command: () => { this.showDocs('I', new ImportacaoDocumento()); } },
          { separator: true },
      ] },
    )

    this.itemsPrint.push( 
      { separator: true },
    )

    this.findAllImpDocsFixos();
  }

  //Lista Importacao Doc / Financeiro
  findAllImpDocsFixos() {
    this.apiImportacaoDocumento.findImpDocFixo()
      .toPromise()
      .then
      ((res: any) => {
        this.listaImpDocsFixos = res;

        if (this.itemsFunctions[8] && this.itemsFunctions[8].items) {
          this.listaImpDocsFixos.forEach(objImpDoc => {
            this.itemsFunctions[8].items?.push(
                { label: objImpDoc.importacaoTipoDocumento.nome, command: () => { this.showDocs('V', objImpDoc); } }
              )
          });
        }
      });
  }

  override createItemsButtonFuctionsItems() {
    this.itemsFunctionsItems.push({ label: this.translocoService.translate('button_timeline'), command: () => { this.openTimeline(); } });
    this.itemsFunctionsItems.push({ separator: true });
    this.itemsFunctionsItems.push({ label: this.translocoService.translate('translation'), command: () => { this.openTraducao() } });
    this.itemsFunctionsItems.push({ separator: true });
    super.createItemsButtonFuctionsItems();
  }

  updateCanal(canal: string) {
    this.updateParcial('canal', canal, 628);
  }

  dataExtraList(res: any) {
  }

  showDocs(acao: string, objImpDocSel: ImportacaoDocumento) {
    let idDoc, titulo, tipoDoc;
    if (acao == 'I') {
      idDoc = 0;
      titulo = ''
      tipoDoc = undefined;
    }
    else {
      idDoc = objImpDocSel.id;
      titulo = objImpDocSel.importacaoTipoDocumento.nome + ' - ' + objImpDocSel.numero;
      tipoDoc = objImpDocSel.importacaoTipoDocumento
    }
    this.showDialogDef(FormImportacaoDocumentoComponent, {tipo: 'DOC', acao: acao, id: idDoc, idImportacao: 0, fixo: 'SIM', objImportacaoTipoDoc: tipoDoc }, titulo, undefined);
  }

  showTimeline() {
    if (this.verifySelRecord(false)) {
      this.showDialogDef(FormImportacaoTimeLineComponent, {idImportacao: this.objSel.id }, this.objSel.numero, undefined);
    }
  }

  openTimeline() {
    let link = `/importacao/forms/formimportacaotimeline`;
    this.router.navigate([`${link}/`], { queryParams: { idImportacao: this.objSel.id } });
  }

  openTraducao() {
    try {
      let listTraducao;
      (this.api as ImportacaoService).findTraducao(this.auth.empresa.id, this.objSel.id).then(
        res => {
          listTraducao = res[0];
          this.colsExt = res[1];
          this.showDialogDef(ListaTraducaoComponent, {list: listTraducao, cols: this.colsExt, id: this.objSel.id }, this.translocoService.translate('value_translation') + ' - ' + this.objSel.numero, undefined);
        }
      );
    } catch (error) {
      this.errorHandler.handle(error);
    }
  }

  override getColorCell(valor: any) {  
    if (valor == 'Verde')
        return 'var(--green-100)'
    else if (valor == 'Vermelho')
      return 'var(--red-100)'
    else if (valor == 'Cinza')
      return 'var(--gray-200)'
    else if (valor == 'Amarelo')
      return 'var(--yellow-100)'

    else if (valor == '02 - Definido')
      return 'var(--yellow-100)'
    else if (valor == '03 - Def. Agentes')
      return 'var(--yellow-200)'
    else if (valor == '04 - Tradução')
      return 'var(--yellow-400)'
    else if (valor == '05 - Info. Canal')
      return 'var(--green-100)'
    else if (valor == '06 - Nfe')
      return 'var(--green-400)'
    else if (valor == '07 - Transporte')
      return 'var(--blue-100)'
    else if (valor == '08 - Concluído')
      return 'var(--teal-300)'

    else if (valor == '02 - WR')
      return 'var(--yellow-100)'
    else if (valor == '03 - AWB Inicial')
      return 'var(--blue-100)'
    else if (valor == '04 - AWB Final')
      return 'var(--blue-300)'
    else if (valor == '05 - Concluído')
      return 'var(--teal-300)'

    else if (valor == '02 - Numerário')
      return 'var(--yellow-100)'
    else if (valor == '03 - DTA')
      return 'var(--blue-100)'
    else if (valor == '04 - Armazenagem')
      return 'var(--purple-100)'
    else if (valor == '05 - DI')
      return 'var(--green-100)'
    else if (valor == '06 - Carregamento')
      return 'var(--cyan-200)'
    else if (valor == '07 - Concluído')
      return 'var(--teal-300)'

    else if (valor == '02 - Armazenagem')
      return 'var(--yellow-100)'
    else if (valor == '03 - Escolta')
      return 'var(--orange-200)'
    else if (valor == '04 - Concluído')
      return 'var(--teal-300)'

    else
      return undefined;
  }

}
