import { AdendoCondicao } from './adendoCondicao';
import { Padrao } from '../generic/padrao';
import { AdendoPecasAplicacao } from './adendoPecasAplicacao';
import { AdendoPecasConstituicao } from './adendoPecasConstituicao';
import { AdendoPecasGrupo } from './adendoPecasGrupo';
import { Fabricante } from '../estoque/fabricante';
import { FabricanteDesconto } from '../estoque/fabricanteDesconto';
import { Unidade } from '../estoque/unidade';
import { Moeda } from '../financeiro/moeda';
import { FiscalAnp } from '../fiscal/fiscalAnp';
import { FiscalCest } from '../fiscal/fiscalCest';
import { FiscalCfop } from '../fiscal/fiscalCfop';
import { FiscalCst } from '../fiscal/fiscalCst';
import { FiscalCstIpi } from '../fiscal/fiscalCstIpi';
import { FiscalCstPis } from '../fiscal/fiscalCstPis';
import { FiscalGrupoCst } from '../fiscal/fiscalGrupoCst';
import { FiscalNcm } from '../fiscal/fiscalNcm';

export class AdendoPecas extends Padrao{

    override idTab = 102;

    adendoPecasAplicacao: AdendoPecasAplicacao = new AdendoPecasAplicacao();
    adendoPecasConstituicao: AdendoPecasConstituicao = new AdendoPecasConstituicao();
    adendoPecasGrupo: AdendoPecasGrupo = new AdendoPecasGrupo();
    adendoCondicao: AdendoCondicao = new AdendoCondicao();
    fiscalAnp: FiscalAnp = new FiscalAnp();
    fiscalCest: FiscalCest = new FiscalCest();
    fiscalCstExterno: FiscalCst = new FiscalCst();
    fiscalCstInterno: FiscalCst = new FiscalCst();
    fiscalCstIpiEntrada: FiscalCstIpi = new FiscalCstIpi();
    fiscalCstIpiSaida: FiscalCstIpi = new FiscalCstIpi();
    fiscalCstPis: FiscalCstPis = new FiscalCstPis();
    fiscalCstCofins: FiscalCstPis = new FiscalCstPis();
    fiscalCfopConsumidorInt: FiscalCfop = new FiscalCfop();
    fiscalCfopConsumidorExt: FiscalCfop = new FiscalCfop();
    fiscalCfopContribuinteInt: FiscalCfop = new FiscalCfop();
    fiscalCfopContribuinteExt: FiscalCfop = new FiscalCfop();
    fiscalGrupoCst: FiscalGrupoCst = new FiscalGrupoCst();
    fiscalNcm: FiscalNcm = new FiscalNcm();
    fabricanteDesconto: FabricanteDesconto = new FabricanteDesconto();
    fabricante: Fabricante = new Fabricante();
    moeda: Moeda = new Moeda();
    unidade: Unidade = new Unidade();
    barra: string;
    descricao: string;
    pn: string;
    valor: number;
    qtde: number;
    qtdeEstoque: number;
    custo: number;//ESSE
    margemLucro: number;
    und: string;
    dataUltCalc: Date;
    dataAtualPreco: Date;
    local: string;
    imagem: string;
    margemCusto: number;//VEZES ESSE 1+ A MARGEM
    valorCore: number;
    label: string;
    ativo: string;
    precoFab: number;
    tipo: string;
    ref: string;
    classificacao: string;
    tributoPisCofins: string;
    tributoIcmsExterno: string;
    tributoIcmsInterno: string;
    percIpi: number;
    percIpiEntrada: number;
    perIcmsInterno: number;
    perIcmsExterno: number;
    percRedBaseExterno: number;
    percRedBaseInterno: number;
    peso: number;
    codigoAnt: string;
    tributoIcmsExportacao: string;
    substTrib: string;
    movEstoque: string;
    serial: string;
    usaEccn: string;
    usaScheduleB: string;
    controlado: string;
    DLarg: number;
    DAltura: number;
    DProfund: number;
    pesoEmb: number;
    matPerig: string;
    obs: string;
    descTecnica: string;
    cd: string;
    percPis: number;
    percCofins: number;
    controleLimitePf: number;
    controleLimitePj: number;
    zerarIcmsProprio: number;
    oneraIcms: number;
}