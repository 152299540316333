import { Injectable } from "@angular/core";
import { Padrao } from "src/app/model/generic/padrao";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { GenericService } from "./generic.service";

@Injectable({
    providedIn: 'root'
})

export class GenericDuploService<T extends Padrao, TItens extends Padrao> extends GenericService<T> {

    constructor(protected http: HttpClient) {
      super(http);
    }

    findAllItens(idCompany: number, idMaster: number): Observable<TItens[]> {
        const urlLocal = `${this.url}/item/${idCompany}/${idMaster}`;
        return this.http.get<TItens[]>(urlLocal);
      }
    
    insertItem(objItem: TItens): Observable<TItens> {
      const urlLocal = `${this.url}/item/add/`;
      return this.http.post<TItens>(urlLocal, objItem, this.httpOptions);
    }
    
    deleteItem(idCompany: number, idItem: number): Observable<TItens> {
      const urlLocal = `${this.url}/item/remove/${idCompany}/${idItem}`;
      return this.http.delete<TItens>(urlLocal, this.httpOptions);
    }
    
}    