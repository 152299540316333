import { Injectable } from "@angular/core";
import { Padrao } from "src/app/model/generic/padrao";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { DadosPesquisa } from "src/app/model/sistema/dadosPesquisa";
import { Arquivo } from "src/app/model/importacao/arquivo";
import { ConstantsAero } from "src/app/demo/components/aerosys/generic/constantsAero";
import { AppConfig } from "src/app.config";

@Injectable({
    providedIn: 'root'
})

export class GenericService<T extends Padrao> {

  protected constantsAero: ConstantsAero = new ConstantsAero();

  url: string;
  resource: string;
  httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json',  })
  };
  httpOptionsPdf = {
      headers: new HttpHeaders({ 'Content-Type': 'application/pdf', })
  };

  constructor(protected http: HttpClient) {
  }

  getHttpClient() {
      return this.http;
  }

  initResource(resource: string) {
      this.resource = resource;
      this.url = AppConfig.urlBasic + this.resource;
  }

  findAll(idCompany: number): Observable<T[]> {
      const urlLocal = `${this.url}/${idCompany}`;
      return this.http.get<T[]>(urlLocal);
  }
    
  findFilterAll(idCompany: number, dadosPesquisa: DadosPesquisa): Observable<T[]> {
    const urlLocal = `${this.url}/list/${idCompany}`;
    return this.http.post<T[]>(urlLocal, dadosPesquisa, this.httpOptions);
  }

  findById(idCompany: number, id: number): Observable<T> {
      const urlLocal = `${this.url}/${idCompany}/${id}`;
      let x: any;
      let obj = this.http.get<T>(urlLocal);
  
      obj.subscribe(res => {
        x = res;
        Object.getOwnPropertyNames(x).forEach(
          function (val, idx, array) {
            //console.log(val + ' -> ' + x[val]);
            if (val == 'dataCad') {
              x[val] = new Date(x[val]);
            }
          });
        });
      return obj;
    }
  
  insert(obj: T): Observable<T> {
    return this.http.post<T>(this.url, obj, this.httpOptions);
  }

  insertMult(obj: T, files: any[]): Observable<T> {
    const urlLocal = `${this.url}/mult`;

    const formData = new FormData();
    for (let file of files) {
      formData.append('upload', file);
    }    
    formData.append('jsonObject', JSON.stringify(obj));

    return this.http.post<T>(urlLocal, formData);
  }

  update(id: number, obj: T): Observable<T> {
    const urlLocal = `${this.url}/${id}`;
    return this.http.put<T>(urlLocal, obj, this.httpOptions);
  }

  updateParcial(idCompany: number, id: number, propriedade: string, valor: string, idRegra: number): Observable<T> {
    const urlLocal = `${this.url}/parcial/${idCompany}/${id}/${propriedade}/${valor}/${idRegra}`;
    return this.http.patch<T>(urlLocal, this.httpOptions);
  }

  delete(idCompany: number, id: number): Observable<T> {
    const urlLocal = `${this.url}/${idCompany}/${id}`;
    return this.http.delete<T>(urlLocal, this.httpOptions);
  }

  // Methods Reports

  generateReport(idCompany: number, ) {
    const urlLocal = `${this.url}/report/${idCompany}/pdf`;
    return this.http.get(urlLocal, {responseType: 'blob'})
    .toPromise();
  }

  // Methods Files

  uploadFiles(event, id: number, infoExtra: string[]) {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    const urlLocal = `${this.url}/upload/${id}/${infoExtra}`;
    console.log(urlLocal);

    const formData = new FormData();
    for (let file of event.files) {
      formData.append('upload', file);
    }    
    
    return this.http.post(`${urlLocal}`, formData)
      .toPromise()
      .then
      ((res: any) => {
        return true;
      })
      .catch((res: any) => {
        return false;
      });
  }

  listFiles(id: number, infoExtra: string[]): Observable<Arquivo[]> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    const urlLocal = `${this.url}/download/${id}/${infoExtra.toString()}`;
    //console.log(urlLocal);
    
    return this.http.get<Arquivo[]>(urlLocal);
  }

  downloadFile(id: number, fileName: string, infoExtra: string[]): Observable<any> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    return this.http.get(`${this.url}/downloadFile/${id}/${fileName}/${infoExtra.toString()}`, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'});
  }

  downloadImage(id: number, fileName: string, infoExtra: string[]): Observable<any> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    return this.http.get(`${this.url}/downloadImage/${id}/${fileName}/${infoExtra.toString()}`, { responseType: 'blob' });
  }

  downloadPdf(id: number, fileName: string, infoExtra: string[]): Observable<any> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    return this.http.get(`${this.url}/downloadPdf/${id}/${fileName}/${infoExtra.toString()}`, { responseType: 'blob'} );
  }

  deleteFile(id: number, fileName: string, infoExtra: string[]): Observable<any> {
    infoExtra = this.verifyAndReturnInfoExtra(infoExtra);
    return this.http.delete(`${this.url}/deleteFile/${id}/${fileName}/${infoExtra.toString()}`);
  }

  // Methods Security

  getPermissao(idCompany: number, idRegra: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/permission/${idCompany}/${idRegra.toString()}`)
  }

  returnValueRule(idCompany: number, idRegra: number): Observable<string> {
    return this.http.get<string>(`${this.url}/rulevalue/${idCompany}/${idRegra.toString()}`)
  }

  // Private Methods

  private verifyAndReturnInfoExtra(infoExtra: string[]): string[] {
    if ((infoExtra) && (infoExtra.length > 0)) 
      return infoExtra
    else
      return ['0'];
  }

}    