import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { SysTabela } from 'src/app/model/sistema/sysTabela';
import { AppConfig } from 'src/app.config';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class SysTabelaService {
  constructor(private http: HttpClient) { }

  findAll(): Observable<SysTabela[]> {
    return this.http.get<SysTabela[]>(AppConfig.urlBasic + 'systabela');
  }

  findById(id: number): Observable<SysTabela> {
    const urlLocal = `${AppConfig.urlBasic}systabela/${id}`;
    return this.http.get<SysTabela>(urlLocal);
  }

  findListById(idTab: number): Observable<SysTabela> {
    const urlLocal = `${AppConfig.urlBasic}systabela/lista/${idTab}`;
    return this.http.get<SysTabela>(urlLocal);
  }

  insert (obj: SysTabela): Observable<SysTabela> {
    return this.http.post<SysTabela>(AppConfig.urlBasic + 'systabela', SysTabela, httpOptions);
  }
  
  update (id: number, obj: SysTabela): Observable<any> {
    const urlLocal = `${AppConfig.urlBasic}systabela/${id}`;
    return this.http.put(urlLocal, SysTabela, httpOptions);
  }
  
  delete (id: number): Observable<SysTabela> {
    const urlLocal = `${AppConfig.urlBasic}systabela/${id}`;
    return this.http.delete<SysTabela>(urlLocal, httpOptions);
  }

}
