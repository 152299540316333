import { MessageService } from 'primeng/api';
import { Injector, OnInit, Component, Input, Output, EventEmitter} from '@angular/core';
import { SysCampoService } from "src/app/services/system/sys-campo.service";
import { SysTabelaService } from "src/app/services/system/sys-tabela.service";
import { TranslocoService } from "@ngneat/transloco";
import { ErrorHandlerService } from "src/app/services/system/error-handler.service";
import { AuthService } from "src/app/services/security/auth.service";
import { Padrao } from "src/app/model/generic/padrao";
import { SysCampo } from "src/app/model/sistema/sysCampo";
import { SysTabela } from "src/app/model/sistema/sysTabela";
import { DadosPesquisa } from "src/app/model/sistema/dadosPesquisa";
import { GlobalFunctions } from '../global-functions';
import { ConstantsAero } from '../constantsAero';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ListaComponent } from '../listapesquisa/lista.component';

@Component({
  selector: 'app-lista-pesquisa',
  templateUrl: './listapesquisa.component.html',
  styleUrls: [ '../listagenerica/listasimples.component.css']
})
export class ListaPesquisaGenericaComponent implements OnInit {

  @Input() api: any;
  @Input() obj: Padrao;
  @Input() viewChek = false;
  @Input() qtdProperties: number = 1;
  @Input() properties: string[];
  @Input() labels: string[];
  @Input() larguraCampos: number[] = [2,4,3,3];
  @Input() disabled = false;
  @Input() limpar = true;
  @Input() textButton = '';
  objOriginal: Padrao;

  @Output() atualizarObjPai = new EventEmitter<Padrao>();

  title = "";

  list: Padrao[] = [];
  listSel: Padrao[];

  cols: any[];

  colsFilter: any[] = [];
  colsDataFilter: any[] = [];

  dadosPesquisa = new DadosPesquisa();
    
  listSysCampo: SysCampo[] = [];
  objSysTabela: SysTabela;
  
  messageService: MessageService;
  translocoService: TranslocoService;

  apiSysFiels: SysCampoService;
  apiSysTabela: SysTabelaService;

  errorHandler: ErrorHandlerService;

  auth: AuthService;

  ref: DynamicDialogRef;
  dialogService: DialogService;

  protected constantsAero: ConstantsAero = new ConstantsAero();
  protected globalFunctions: GlobalFunctions;

  constructor(
    protected injectorObj: Injector,
    ) 
  {
    this.messageService = this.injectorObj.get(MessageService);
    this.translocoService = this.injectorObj.get(TranslocoService);

    this.apiSysFiels = this.injectorObj.get(SysCampoService);
    this.apiSysTabela = this.injectorObj.get(SysTabelaService);
    
    this.errorHandler = this.injectorObj.get(ErrorHandlerService);

    this.auth = this.injectorObj.get(AuthService);

    this.dialogService = this.injectorObj.get(DialogService);
  }

  ngOnInit(): void {
    this.objOriginal = {...this.obj};
    this.globalFunctions = new GlobalFunctions(this.translocoService);
    this.findTable();
  }


  find() {
    //console.log(this.dadosPesquisa);
    //if ((this.dadosPesquisa.valor.trim().length > 0) || (this.dadosPesquisa.campoData.trim().length > 0)) {
      if (true) {
        if (this.dadosPesquisa.campo == 'id') {
          let cod: number = parseInt(this.dadosPesquisa.valor);
          if (Number.isInteger(cod)) {
            this.findByIdList(cod);
          }
          else {
            this.errorHandler.handle(this.translocoService.translate('message_enter_only_numbers_integer'));
          }
        }
        else {
          this.api.findFilterAll(this.auth.empresa.id, this.dadosPesquisa)
          .toPromise()
          .then
          ((res: any) => {
            this.list = res;
            //console.log(this.dadosPesquisa);
            //console.log(this.list);
            
          })
          .catch
          (( error ) => {
            this.errorHandler.handle(error);
          }
          );
        }
      }
      else {
        this.api.findAll(this.auth.empresa.id)
          .toPromise()
          .then
          ((res: any) => {
            this.list = res;
          })
          .catch
          (( error ) => {
            this.errorHandler.handle(error);
          }
          );
        }
    }

  findByIdList(id: number) {
    this.api.findById(this.auth.empresa.id, id)
      .toPromise()
      .then
      ((res: any) => {
        this.list = [];
        if (res) {
          this.obj = res;
          this.list.push(this.obj);
        }
    })
  }

  findById(id: number) {
    this.api.findById(this.auth.empresa.id, id)
      .toPromise()
      .then
      ((res: any) => {
        this.obj = res;
        this.obj.dataCad = new Date(res.dataCad);
        this.atualizarObjPai.emit(this.obj);
      })
  }

  clearObj() {
    this.obj = this.objOriginal;
    this.atualizarObjPai.emit(this.obj);
  }

  findTable() {
    this.apiSysTabela.findListById(this.obj.idTab)
      .toPromise()
      .then
      ((res: any) => {
        this.objSysTabela = res;
        const t = this.constantsAero.PREFIX_TABLE + this.objSysTabela.id;
        this.title = this.translocoService.translate(t);

        this.findFields();
      });
  }

  findFields() {
    this.apiSysFiels.findListDefaultByIdTable(this.obj.idTab)
      .toPromise()
      .then
      ((res: any) => {
        this.listSysCampo = res;

        this.cols = this.convertFieldsToColsTable(this.listSysCampo);
        this.convertFieldsToColsFilter();

        if (this.objSysTabela.pesqAuto == 'SIM') {
            this.find();
        }
      });
  }

  convertFieldsToColsTable(listSysCampoTemp: SysCampo[]) {
    return listSysCampoTemp.map(c => {
      const idField = c.id;
      let label = this.constantsAero.PREFIX_FIELD + idField;

      if (c.nomeProp == 'dataCad') {
        label = this.translocoService.translate('field_default_dateIn');
      }
      if (c.nomeProp == 'usuarioCad') {
        label = this.translocoService.translate('field_default_user');
      }
      if (c.nomeProp == 'empresaCad') {
        label = this.translocoService.translate('field_company');
      }

      return { field: c.nomeProp, header: label, width: c.tamHorzWeb + '%', type: c.tipoWeb,
               fieldId: c.nomePropId, fieldView: c.nomePropVisao, widthList: c.tamHorz}
    })    
  }

  convertFieldsToColsFilter() {
    let i = 0;    
    this.colsFilter = [];
    this.colsDataFilter = [];

    let label = this.translocoService.translate('all_items');
    this.colsFilter.push({ 'value': '*', 'label': label});
    
    label = this.translocoService.translate('bar_code');
    this.colsFilter.push({ 'value': 'id', 'label': label});

    return this.listSysCampo.map(c => {
      const idField = c.id;
      label = this.translocoService.translate(this.constantsAero.PREFIX_FIELD + idField);
      if (c.prefixo != 'CALCULADO') {
        if (c.tipoWeb == 'Data') {
          if (c.nomeProp == 'dataCad') {
            label = this.translocoService.translate('field_default_dateIn');
          }
          this.colsDataFilter.push({ 'value': c.nomeProp, 'label': label});
          i++;
          if (i == 1) {
            this.dadosPesquisa.dataInicial.setDate(new Date().getDate() - c.intervaloDataInicio);
            this.dadosPesquisa.dataFinal.setDate(new Date().getDate() + c.intervaloDataTermino);
            this.dadosPesquisa.campoData = c.nomeProp;
          }
        }
        else {
          if (c.nomeProp == 'usuarioCad') {
            label = this.translocoService.translate('field_default_user');
          }
          if (c.nomeProp == 'empresaCad') {
            label = this.translocoService.translate('field_company');
          }
          this.colsFilter.push({ 'value': c.nomeProp, 'label': label});
        }
      }
    })    
  }

  showDialog() {
    this.showDialogDef(ListaComponent, {objSel: this.obj, api: this.api, viewChek: this.viewChek }, this.translocoService.translate('action_pesquisar'));
  }  

  showDialogDef(component: any, data: any, titulo: string) {
    this.ref = this.dialogService.open(component, {
        header: titulo,
        //style: {width: '85vw'},
        width:'100%', 
        height:'100%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: true,
        draggable: true,
        modal: true,
        data: data
      });
    
      this.ref.onClose.subscribe((res: any) => {
        if (res) {
          if (this.viewChek)
            this.atualizarObjPai.emit(res);
          else
            this.findById(res);
        }
      });
  }  
}