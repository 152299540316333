import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bi',
  templateUrl: './bi.component.html',
  styleUrls: ['./bi.component.scss']
})
export class BiComponent implements OnInit {

  constructor() {
    this.viewportHeight = window.innerHeight * 0.9; 
  }

  viewportHeight: number = 0;

  ngOnInit(): void {

      

  }

}
