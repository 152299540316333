import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Padrao } from 'src/app/model/generic/padrao';
import { Arquivo } from 'src/app/model/importacao/arquivo';
import { ErrorHandlerService } from 'src/app/services/system/error-handler.service';
import { ConstantsAero } from '../constantsAero';
import { GenericModule } from '../generic.module';
import { GlobalFunctions } from '../global-functions';
import { saveAs } from 'file-saver';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormViewPdfComponent } from '../formviewpdf/formviewpdf.component';

@Component({
  selector: 'app-lista-arquivos',
  templateUrl: './listaarquivos.component.html',
  styleUrls: [ '../listagenerica/listasimples.component.css']
})
export class ListaArquivosComponent implements OnInit, OnChanges {

  @Input() obj: Padrao;
  @Input() api: any;
  @Input() infoExtra: string[] = [];
  
  @Input() numLinhas: number = 1;
  @Input() largMd: number = 3;
  @Input() largLg: number = 2;
  @Input() paginacao = false;
  @Input() excluirFile = true;
  @Input() atualizar = true;
  
  listaArquivos: any;
  arqSel: Arquivo;
  fileStatus = { status: '', requestType: '', percent: 0 };
  filenames: string[] = [];
  
  @Output() atualizarListaPai = new EventEmitter();
  
  protected constantsAero: ConstantsAero = new ConstantsAero();
  protected globalFunctions: GlobalFunctions;

  itemsFunctionsFiles: MenuItem[];  

  public refDialog: DynamicDialogRef;
  public configDialog: DynamicDialogConfig;

  constructor(
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private errorHandler: ErrorHandlerService,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private injectorObj: Injector
      ) {

        try {
          this.refDialog    = this.injectorObj.get(DynamicDialogRef);
          this.configDialog = this.injectorObj.get(DynamicDialogConfig);
        } catch (error) {
        }    
  
  }

  ngOnInit(): void {
    this.itemsFunctionsFiles = [
      { label: this.translocoService.translate('button_download'), command: () => { this.dowloadFile() } }, // Visualizar
      { separator: true },
      { label: this.translocoService.translate('button_delete'), command: () => { this.deleteFile() } }, // Inserir
    ];

    this.loadDataExternal();
    this.listarArquivos();
  }

  ngOnChanges() {
    this.loadDataExternal();
    this.listarArquivos();
  }
  
  loadDataExternal() {
    if ((this.configDialog) && (this.configDialog.data)) {
      if (this.configDialog.data.obj)
        this.obj = this.configDialog.data.obj;
      if (this.configDialog.data.api)
        this.api   = this.configDialog.data.api;
      if (this.configDialog.data.atualizar)
        this.atualizar = this.configDialog.data.atualizar;
      if (this.configDialog.data.infoExtra)
        this.infoExtra = this.configDialog.data.infoExtra;
    }
  }

  listarArquivos() {
    if (this.obj) {
      this.api.listFiles(this.obj.id, this.infoExtra)
      .toPromise()
      .then
      ((res: any) => {
        this.listaArquivos = res;
        this.loadImagesAndPdfs();
      });
    }
  }

  loadImagesAndPdfs() {
    this.listaArquivos.sort( this.sortFileName );

    this.listaArquivos.forEach(f => {
      if (f.extensao == 'pdf') {
        this.api.downloadPdf(this.obj.id, f.nome, this.infoExtra).subscribe(data => {this.loadFile(f, data); });
      }
      else if (this.getIconeTipoArquivo(f.nome, true)=='bmp') {
        this.api.downloadImage(this.obj.id, f.nome, this.infoExtra).subscribe(data => {this.loadFile(f, data); });
      }
    });
  }

  loadFile(f, data) {
    const reader = new FileReader();
    reader.onload = () => {
      f.caminho = reader.result as string;
    };
    reader.readAsDataURL(data);
  }

  selectedFile(arquivo: Arquivo) {
    this.arqSel = arquivo;
  }

  dowloadFile(): void {
    this.api.downloadFile(this.obj.id, this.arqSel.nome, this.infoExtra).subscribe(
      event => {
        this.barraProgresso(event, this.arqSel.nome, this.arqSel);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  showDialogPdf() {
    this.showDialogDef(FormViewPdfComponent, {'caminhoArqSel': this.arqSel.caminho}, this.arqSel.nome);
  }

  showDialogDef(component: any, data: any, titulo: string) {
    this.ref = this.dialogService.open(component, {
        header: titulo,
        //style: {width: '85vw'},
        width:'100%', 
        height:'100%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: true,
        draggable: true,
        modal: true,
        data: data
      });
    
      this.ref.onClose.subscribe((objRes: any) => {
      });
  }  


  deleteFile() {
    const mens = this.translocoService.translate('mens_delete_pre_confirm') + this.translocoService.translate('file');
    const header = this.translocoService.translate('mens_confirm');
    const btYes = this.translocoService.translate('yes');
    const btNo = this.translocoService.translate('no');
    const mensConfirm = this.translocoService.translate('file') + this.translocoService.translate('mens_delete_pos_confirm');

    this.confirmationService.confirm({
      message: mens,
      header: header,
      icon: 'pi pi-question-circle',
      acceptLabel: btYes,
      rejectLabel: btNo,
      accept: () => {
        this.api.deleteFile(this.obj.id, this.arqSel.nome, this.infoExtra)
          .toPromise()
          .then((res: any) => {
            this.listarArquivos();
            this.messageService.add({ severity: 'success', summary: mensConfirm, detail: '' });
          })
          .catch((res: any) => {
            //console.log(res)
            if ((!res) || (res.status === 400)) {
              this.errorHandler.handle(this.translocoService.translate('message_delete_failure') + ' ' + this.translocoService.translate('file'));
            }
            else {
              this.errorHandler.handle(res);      
            }
          })
      },
      reject: () => {
      }
    });
  }

  barraProgresso(httpEvent: HttpEvent<string[] | Blob>, nome: string, arq: Arquivo): void {
    switch (httpEvent.type) {
      case HttpEventType.Response:
        if (httpEvent.body instanceof Array) {
          this.fileStatus.status = 'Finalizado';
          for (const filename of httpEvent.body) {
            this.filenames.unshift(filename);
          }
        } else {
            saveAs(new File([httpEvent.body!], nome,
              { type: `${httpEvent.headers.get('Content-Type')};charset=utf-8` }));
        }
    }
  }

  getTipoArquivo(nome: string) {  
    let ext: string | undefined;
    ext = nome.split('.').pop();
    return ext?.toLowerCase();
  }

  getIconeTipoArquivo(nome: string, iconePadrao: boolean): string {  
    let ext: string | undefined = this.getTipoArquivo(nome);
    if (ext) {
      if ('doc docx'.indexOf(ext) >= 0)
        return 'doc'
      else if ('xls xlsx csv'.indexOf(ext) >= 0)
        return 'csv'
      else if ('pdf'.indexOf(ext) >= 0)
        return 'pdf'
      else if ('txt'.indexOf(ext) >= 0)
        return 'txt'
      else if ('png bmp jpg jpeg gif tif tiff svg'.indexOf(ext) >= 0)
        return 'bmp'
      else if ('avi mov mp4 mpg mpeg mkv wmv'.indexOf(ext) >= 0)
        return 'mkv'
      else {
        if (iconePadrao)
          return 'tmp'
        else
          return '';
      }
    }
    else
      if (iconePadrao)
        return 'tmp'
      else
        return '';
  }

  private sortFileName( a, b ) {
    if (a && b && a.nome && b.nome) {
      if ( a.nome < b.nome ){
        return -1;
      }
      if ( a.nome > b.nome ){
        return 1;
      }
      return 0;
    } 
    else {
      return 0;
    }
  }
 
}