import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { OnInit, Injectable, ViewChild, Injector, OnChanges, OnDestroy} from '@angular/core';
import { TranslocoService } from "@ngneat/transloco";
import { Padrao } from "src/app/model/generic/padrao";
import { GlobalFunctions } from '../global-functions';
import { ConstantsAero } from '../constantsAero';
import * as FileSaver from 'file-saver';
import { Table } from 'primeng/table';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ErrorHandlerService } from 'src/app/services/system/error-handler.service';
import { AuthService } from 'src/app/services/security/auth.service';
import { GeralListaModule } from "../../geral/listas/gerallista.module";

@Injectable()
export abstract class ListaVisualComponent implements OnInit, OnChanges, OnDestroy {

  list: Padrao[] = [];
  cols: any[];
  title: string;
  id: number;

  retId: any;
  objSel: Padrao;

  @ViewChild('dt') dt: Table;

  itemsPrint: MenuItem[];
  itemsFunctions: MenuItem[];

  constantsAero: ConstantsAero = new ConstantsAero();
  globalFunctions: GlobalFunctions;

  translocoService: TranslocoService;
  auth: AuthService;
  errorHandler: ErrorHandlerService;
  messageService: MessageService;
  confirmationService: ConfirmationService;

  ref: DynamicDialogRef;
  dialogService: DialogService;

  refDialog: DynamicDialogRef;
  configDialog: DynamicDialogConfig;

  public viewportWidth: number = 0;
  public viewportHeight: number = 0;

  constructor(
    protected injectorObj: Injector
  ) {
    this.viewportWidth = window.innerWidth;
    this.viewportHeight = window.innerHeight;        

    this.translocoService = this.injectorObj.get(TranslocoService);
    this.auth = this.injectorObj.get(AuthService);
    this.errorHandler = this.injectorObj.get(ErrorHandlerService);
    this.messageService = this.injectorObj.get(MessageService);
    this.confirmationService = this.injectorObj.get(ConfirmationService);

    this.dialogService = this.injectorObj.get(DialogService);
    this.configDialog = this.injectorObj.get(DynamicDialogConfig);

    if (this.configDialog.data) {
      this.list   = this.configDialog.data.list;
      this.cols   = this.configDialog.data.cols;
      this.title  = this.configDialog.data.title;
      if (this.configDialog.data.id)
        this.id = this.configDialog.data.id;
    }

    this.globalFunctions = new GlobalFunctions(this.translocoService);

    this.itemsPrint = [
      { label: this.translocoService.translate('menu_print_list_default'), command: () => { this.exportPdf(); } }
    ];

    const expCsv = this.translocoService.translate('menu_function_export_csv');
    const expExc = this.translocoService.translate('menu_function_export_spreadsheet');
    const mult = this.translocoService.translate('menu_function_export_mult_check');

    this.itemsFunctions = [
      { label: expCsv, command: () => { this.exportCSVLocal(this.dt); } },
      { label: expExc, command: () => { this.exportExcel(); } }
    ];
  }

  abstract getColorCell(valor: any);

  ngOnInit(): void {
  }

  ngOnChanges() {
  } 

  exportPdf() {
    /*
    const doc = new jsPDF();
    this.colsExport = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    doc.autoTable(this.colsExport, this.list);
    doc.save(this.translocoService.translate('menu_function_export_title_file') + this.title + '.pdf');*/
/*    this.api.generateReport(this.auth.empresa.id)
    .then((relatorio: any) => {
      const url = window.URL.createObjectURL(relatorio);
      window.open(url);
    });*/
  }

  exportCSVLocal(dataTable) {
    //this.colsExport = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    //this.grid.exportFilename = 'teste'//this.translocoService.translate('menu_function_export_title_file') + this.title;
    //this.grid.csvSeparator = Constants.SEPAR_CSV; // Constat Global
    //this.dt.exportCSV();
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.list);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.title);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  showDialogDef(component: any, data: any, titulo: string, fn: any) {
    this.ref = this.dialogService.open(component, {
        header: titulo,
        //style: {width: '85vw'},
        width:'100%', 
        height:'100%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: true,
        draggable: true,
        modal: true,
        data: data
      });
    
      this.ref.onClose.subscribe((objRes: any) => {
        if ((fn) /*&& objRes*/) {
          this.retId = objRes;
          fn();
        }
      });
  }  

  onRowSelectObj(event) {
      this.objSel = event.data;
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }  
}