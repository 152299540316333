import { Padrao } from '../generic/padrao';
import { Compra } from '../compra/compra';
import { CentroCusto } from './centroCusto';
import { TipoCondPagto } from './tipoCondPagto';
import { Pessoa } from '../pessoa/pessoa';
import { FiscalNfe } from '../fiscal/fiscalNfe';
import { Venda } from '../venda/venda';

export class ContasPagar extends Padrao{

    override idTab = 108;

    centroCusto: CentroCusto = new CentroCusto();
    tipoCondPagto: TipoCondPagto = new TipoCondPagto();
    pessoa: Pessoa = new Pessoa();
    idCpr: number;
    nfeId: number;
    venId: number;
    dataCadastro: Date;
    dataVenc: Date;
    nTitulo: string;
    parcela: string;
    valor: number;
    status: string;
    mesAnoRef: Date;
    tipo: string;
    categ: string;
    obs: string;
    autoData: Date;
    autoUsuario: number;
    autoNumLote: string;
}