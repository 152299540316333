import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslocoService } from '@ngneat/transloco';
import { Empresa } from 'src/app/model/empresa/empresa';
import { AcessosGrupo } from '../../model/security/acessosGrupo';
import { UsuarioInfo } from '../../model/security/usuarioInfo';
import { AppConfig } from 'src/app.config';
import { EmpresaService } from '../empresa/empresa.service';
import { DadosPesquisa } from 'src/app/model/sistema/dadosPesquisa';

@Injectable()
export class AuthService {

  jwtPayload: any;

  empresa: Empresa = new Empresa();
  listaEmpresa: Empresa[] = [];
  public usuarioInfo: UsuarioInfo = new UsuarioInfo();
  
  // Upload
  uploadedFiles: any[] = [];
  
  menuItemSelecionado: AcessosGrupo = new AcessosGrupo();

  //Pesquisa
  dadosPesquisa: DadosPesquisa;

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private translocoService: TranslocoService,
    private router: Router
  ) { 
        this.carregarToken();
  }

  login(login: string, senha: string): Promise<any> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Authorization', 'Basic YW5ndWxhcjpBbmd1bGFy');

    const body = `username=${login}&password=${senha}&grant_type=password`;
    const oauthTokenUrl = AppConfig.urlBasic + 'oauth/token';

    return this.http.post(oauthTokenUrl, body, { headers, withCredentials: true })
      .toPromise()
      .then((response: any) => {        
        this.salvarToken(response['access_token']);
        this.router.navigate(['/home']);
      })
      .catch(response => {
        const responseJson = response;
        if ((!responseJson) || (responseJson.status === 400)) {
          //if (responseJson.error === 'invalid_grant') {
            return Promise.reject(this.translocoService.translate('message_login_failure'));
          //}
        }
        return Promise.reject(response);      
      });
  }

  novoAccessToken(): Promise<any> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Authorization', 'Basic YW5ndWxhcjpBbmd1bGFy');

    const body = 'grant_type=refresh_token';

    const oauthTokenUrl = AppConfig.urlBasic + 'oauth/token';

    return this.http.post(oauthTokenUrl, body, { headers, withCredentials: true })
      .toPromise()
      .then((response: any) => {
        this.salvarToken(response['access_token']);
        //console.log('Novo access token criado!');
        return Promise.resolve(null);
      })
      .catch(response => {
        console.error('Erro ao renovar token.', response);
        return Promise.resolve(null);
      });
  }

  acessoTokenInvalido() {
    const token = localStorage.getItem('token');
    return !token || this.jwtHelper.isTokenExpired(token);
  }

  logout() {
    const tokensRevokeUrl = AppConfig.urlBasic + 'tokens/revoke';
    return this.http.delete(tokensRevokeUrl, { withCredentials: true })
      .toPromise()
      .then(() => {
        this.limparAccessToken();
      });
  }
  
  private salvarToken(token: any) {
    this.jwtPayload = this.jwtHelper.decodeToken(token);
    this.usuarioInfo = this.jwtPayload['usuarioInfo'];
    //console.log(this.jwtPayload)
    //console.log(this.usuarioInfo)
    localStorage.setItem('token', token);
  }

  private carregarToken() {
    const token = localStorage.getItem('token');
    if (token) {
      this.salvarToken(token);
    }
  }

  private limparAccessToken() {
    localStorage.removeItem('token');
    this.jwtPayload = null;
    this.usuarioInfo = new UsuarioInfo();
    //localStorage.removeItem('empresa');
  }

  getCorEmpresaSel() {
    let cor = '#' + this.empresa.cor.substring(7,8) + this.empresa.cor.substring(5,6) + this.empresa.cor.substring(3,4);
    return cor;
  }

  getCorEmpresa(emp: Empresa) {
    let cor: string = '#' + emp.cor.substring(7,9) + emp.cor.substring(5,7) + emp.cor.substring(3,5);
    return cor;
  }

}
