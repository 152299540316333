import { TranslocoService } from "@ngneat/transloco";
import { ConstantsAero } from "./constantsAero";

export class GlobalFunctions {

  protected constantsAero: ConstantsAero = new ConstantsAero();

  constructor(
    public translocoService: TranslocoService
    ) 
  {

  }
    
  extrairOpcoes(opcoesFixas: string) {
    return opcoesFixas.split(';');
  }

  getDataFormat() {
    return this.translocoService.translate('date_short_format_list');
  }

  getDataFormatShort() {
    return this.translocoService.translate('date_short_format_list_yy');
  }

  getDataHoraFormat() {
    return this.translocoService.translate('datehour_short_format_list');
  }

  getIdFormat(valor) {
    return ("0000000" + valor).slice(-7);
  }

  getFormatInteger(valor, qtde) {
    let zero = "";
    for (let i = 1; i < qtde - valor.length; i++) {
      zero = zero + "0";
    } 
    return (zero + valor);
  }

  getFormatNumber(valor, maxCasasDecimais = this.constantsAero.maxDigitisFieldsNumber) {
    return new Intl.NumberFormat(this.translocoService.getActiveLang(), { minimumFractionDigits: 2,  maximumFractionDigits: maxCasasDecimais }).format(valor)   
  }  

  calcularTotais(campo, listTemp, subObj) {
    let total = 0;
    let valor: number;
    listTemp.forEach(objTemp => {
      if (subObj) {
        try {
          valor = +objTemp[subObj][campo];
        } catch (error) {
          valor = 0;          
        }
      }
      else if (objTemp[campo]) {
        try {
          valor = +objTemp[campo];
        } catch (error) {
          valor = 0;          
        }
      }
      else {
        valor = 0;
      }
      if (valor) {
        total = total + valor;
      }
    });
    return total;
  }

  getTotalColuna(campo, listTemp, subCampo) {
    return this.getFormatNumber(this.calcularTotais(campo, listTemp, subCampo));
  }

  getTotalRecords(listTemp) {
    return listTemp.length
  }
  
  removeObjFromObjectArray(lista: any, id: number) {
    lista.forEach((value, index)=>{
        if (value.id == id ) 
          lista.splice(index, 1);
    });
  } 

  sortColumnsTable( array, nameColumn: string ) {
    return array.sort(function(a, b) {
      if (a && b && a.nome && b.nome) {
        if ( a[nameColumn] < b[nameColumn] ){
          return -1;
        }
        if ( a[nameColumn] > b[nameColumn] ){
          return 1;
        }
        return 0;
      } 
      else {
        return 0;
      }
    });
  }
  
}