import { ContasPagar } from './../../model/financeiro/contasPagar';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';

@Injectable({
  providedIn: 'root'
})
export class ContasPagarService extends GenericService<ContasPagar>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("contaspagar")
  }
}
