
import { Component, Injector, Input, OnInit } from '@angular/core';
import { Email } from 'src/app/model/email/email.model';
import { EmailService } from 'src/app/services/email/email.service';
import { FormGenerico } from '../../../generic/formgenerico/formgenerico';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { Pessoa } from 'src/app/model/pessoa/pessoa';

@Component({
  selector: 'app-form-email',
  templateUrl: './formemail.component.html',
  styleUrls: ['../../../generic/formgenerico/formgenerico.component.css']
})

export class FormEmailComponent extends FormGenerico<Email> implements OnInit {

  displayEmail = false;
  displayPessoaEmail: boolean = false;

  loading = [false, false, false, false];
  uploadedFiles: any[] = [];
  listaPessoaEmail: Pessoa[] = [];

  pessoa: Pessoa = new Pessoa();

  constructor(
    injectorObj: Injector,
    api: EmailService,
    private emailService: EmailService,
    public apiEmailPessoa: PessoaService,) {
    super(injectorObj, api);
    this.obj = new Email();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.apiEmailPessoa.findAll(this.auth.empresa.id)
      .toPromise()
      .then((res: any) => {
        this.listaPessoaEmail = res;
      });
  }

  override dataExtraObj(res: any) {
  }

  //Responsavel por fazer o efeito do button de envio
  load(index) {
    this.loading[index] = true;
    setTimeout(() => this.loading[index] = false, 1000);
  }

  //Responsavel Pelo Envio de E-mail
  sendEmail() {
    this.obj.files = this.files;
    this.emailService.sendEmail(this.obj.to, this.obj.subject, this.obj.text, this.obj.files)
      .subscribe(response => {
        //console.log(response);
      })
    const message = this.translocoService.translate('em_env_sucesso');
    this.messageService.add({ severity: 'success', summary: `${message}`, detail: '' });
    this.resetForm();
  }

  //Responsavel por limpar os campos do formulario de envio ao enviar
  resetForm() {
    this.obj.to = '';
    this.obj.subject = '';
    this.obj.text = '';
    this.obj.files = [];
    this.uploadedFiles = [];
  }

  //Pessoa E-mail
  findAllPessoaEmailShowDialog() {
    this.displayPessoaEmail = true;
  }

  
  findAllEmailShowDialog() {
    this.displayEmail = true;
  } 

  //Responsavel por anexar os arquivos
  uploadFiles(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];

      this.files.push(file);

      this.uploadedFiles.push({
        name: file.name,
        size: file.size,
        type: file.type,
        date: new Date()
      });
    }
  }

  //Responsavel por Limpar os arquivos de upload
  clearFiles() {
    this.uploadedFiles = [];
  }

  //Responsavel por remover anexos
  removeFile(file) {
    let index = this.uploadedFiles.indexOf(file);
    this.uploadedFiles.splice(index, 1);
  }

}