import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from '../services/security/auth.service';
import { LayoutService } from "./service/app.layout.service";
import { EmailService } from '../services/email/email.service';
import { FormEmailComponent } from '../demo/components/aerosys/email/forms/formemail/formemail.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    itensConfig: MenuItem[];

    ref: DynamicDialogRef;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        private readonly translocoService: TranslocoService,
        public auth: AuthService,
        private router: Router,
        private messageService: MessageService,
        private dialogService: DialogService,) { 
    }

    ngOnInit() {
        this.itensConfig = [{
            label: this.translocoService.translate('languages'),
            items: [
                {label: this.translocoService.translate('langBr'), icon: 'pi pi-fw pi-language', command: () => {this.setLang('pt-BR');}},
                {label: this.translocoService.translate('langEn'), icon: 'pi pi-fw pi-language', command: () => {this.setLang('en');}}
            ]
        },
        {
            label: this.translocoService.translate('button_actions'),
            items: [
                {label: this.translocoService.translate('button_logout'), icon: 'pi pi-fw pi-times', command: () => {this.logout();}}
            ]
        },
        {
            label: this.translocoService.translate('button_actions'),
            items: [
                {label: this.translocoService.translate('button_email'), icon: 'pi pi-fw pi-envelope', command: () => this.openEmail(undefined, undefined)}
            ]
        }];
    }    

    logout() {
        this.auth.logout().
        then(() => {
            this.router.navigate(['auth/login']);
        })
        .catch()
    }
    
    setLang(lang) {
        this.translocoService.setActiveLang(lang);
    }    

    getLang() {
        this.translocoService.getActiveLang();
    }

    abrirEmpresas() {
        this.auth.empresa
    }

    mensEmpresaSelecionada(event) {
        this.messageService.add({severity: 'info', summary: 'Empresa Selecionada', detail: ''});
    }

    openEmail(data: any, fn: any) {
        this.ref = this.dialogService.open(FormEmailComponent, {
            header: "Envio de Email",
            //style: {width: '85vw'},
            width:'100%', 
            height:'100%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 10000,
            maximizable: true,
            draggable: true,
            modal: true,
            data: data
          });
        
          this.ref.onClose.subscribe((objRes: any) => {
            if ((fn) /*&& objRes*/) {
              fn();
            }
          });
      }
}
