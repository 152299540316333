import { Padrao } from '../generic/padrao';

export class CentroCusto extends Padrao{

    override idTab = 125;

    nivel: number;
    item: string;
    descricao: string;
    tipo: string;
    idPai: number;
    ativa: string;
    obs: string;
}