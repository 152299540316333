import { AdendoPecas } from './../../model/adendo/adendoPecas';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from '../generics/generic.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdendoPecasService extends GenericService<AdendoPecas>{
  constructor(http: HttpClient) { 
    super(http);
    super.initResource("adendopecas")
  }

  updateConstituicao(idCompany: number, id: number, idConstituicao: number, idRegra: number): Observable<AdendoPecas> {
    const urlLocal = `${this.url}/constituicao/${idCompany}/${id}/${idConstituicao}/${idRegra}`;
    return this.http.patch<AdendoPecas>(urlLocal, this.httpOptions);
  }

  updateAplicacao(idCompany: number, id: number, idAplicacao: number, idRegra: number): Observable<AdendoPecas> {
    const urlLocal = `${this.url}/aplicacao/${idCompany}/${id}/${idAplicacao}/${idRegra}`;
    return this.http.patch<AdendoPecas>(urlLocal, this.httpOptions);
  }

  
  getPorPN(idCompany: number, pn: string): Observable<AdendoPecas[]> {
    const url = `${this.url}/pn/${idCompany}/${pn}`;
    return this.http.get<AdendoPecas[]>(url);
  }

}
