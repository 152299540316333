import { Padrao } from '../generic/padrao';

export class Fabricante extends Padrao{

    override idTab = 95;

    nome: string;
    origem: string;
    nomeImportacao: string;
    prazoAtualDias: number;
}